// Data from here: https://raw.githubusercontent.com/deldersveld/topojson/master/countries/ireland/ireland-counties.json

export function topo() {
  return {
    type: 'Topology',
    transform: {
      scale: [0.0003409257360480425, 0.00018480682339976098],
      translate: [-10.654165999999918, 51.424862000000125],
    },
    arcs: [
      [
        [11914, 6944],
        [-13, -55],
        [-2, -144],
        [-51, -93],
        [-87, -55],
        [-105, -38],
        [-110, -21],
        [-23, -55],
        [-9, -85],
        [7, -135],
        [-101, -243],
        [-30, -93],
        [-114, -211],
        [-55, -76],
        [-181, -13],
        [-53, -89],
        [-23, -11],
      ],
      [
        [10964, 5527],
        [-5, 49],
        [0, 305],
        [23, 228],
        [0, 106],
        [-13, 93],
        [-83, 173],
        [-89, 139],
        [-7, 76],
        [66, 174],
        [0, 59],
        [-34, 34],
        [-46, -5],
        [-140, 55],
        [-75, 11],
        [-87, 29],
        [-21, 22],
        [9, 160],
        [44, 195],
        [19, 135],
      ],
      [
        [10525, 7565],
        [35, -19],
        [165, 0],
        [41, -7],
        [75, 94],
        [61, 30],
        [63, 85],
      ],
      [
        [10965, 7748],
        [93, -82],
        [59, 4],
        [42, 110],
        [68, 76],
        [195, 9],
        [94, 52],
        [43, 113],
        [3, 84],
      ],
      [
        [11562, 8114],
        [32, 17],
        [69, -4],
        [165, -76],
        [96, -34],
        [96, 0],
        [41, -13],
        [14, -42],
        [60, -17],
        [41, 47],
        [32, 8],
        [85, -51],
        [-3, -46],
        [-36, -63],
        [0, -140],
        [-16, -51],
        [-151, -97],
        [-76, 0],
        [-37, 21],
        [-62, 68],
        [-75, 0],
        [-37, -30],
        [-18, -55],
        [-9, -152],
        [34, -110],
        [62, -55],
        [64, -101],
        [-2, -144],
        [-17, -50],
      ],
      [
        [9804, 14552],
        [11, -17],
        [147, -95],
        [204, -75],
        [123, 6],
        [58, 75],
        [41, -37],
        [225, -7],
        [48, -37],
        [95, -176],
        [99, -164],
        [72, -44],
        [115, -145],
        [113, -182],
        [126, -151],
        [109, -82],
        [52, -17],
      ],
      [
        [11442, 13404],
        [0, -9],
      ],
      [
        [11442, 13395],
        [-31, -87],
        [-45, -56],
        [-109, 31],
        [-143, 0],
        [-123, -25],
        [-105, 0],
        [-41, -63],
        [17, -151],
        [75, -132],
        [20, -76],
        [-30, -75],
        [-79, -88],
        [-208, -50],
        [-150, 47],
        [-75, 56],
        [-116, 38],
        [-126, -12],
        [-71, -57],
        [-58, 0],
        [-58, 63],
        [-52, 31],
        [-23, -24],
      ],
      [
        [9911, 12765],
        [-21, 62],
        [-51, 13],
        [-201, -38],
        [-77, -49],
      ],
      [
        [9561, 12753],
        [-39, -20],
        [-32, 22],
        [-46, 95],
        [-110, 197],
        [-68, 85],
        [-79, 40],
        [-133, 41],
        [-30, 32],
        [-28, 126],
        [-27, 13],
        [-30, 211],
      ],
      [
        [8939, 13595],
        [-4, 65],
        [31, 114],
        [3, 75],
        [-24, 113],
        [-153, 183],
        [-147, 138],
        [-129, 163],
        [-28, 57],
        [-58, 19],
        [-75, -6],
        [-139, -51],
        [-89, 145],
        [-58, 113],
        [-68, 88],
        [-150, 145],
        [-72, 31],
        [-51, -19],
        [-48, 13],
        [-6, 101],
        [10, 144],
        [112, 233],
        [68, 88],
        [59, 46],
      ],
      [
        [7923, 15593],
        [34, -39],
        [186, -55],
        [-2, -105],
        [30, -107],
        [3, -140],
        [47, -64],
        [91, -71],
        [60, 21],
        [302, -3],
        [64, -110],
        [54, 12],
        [21, -46],
        [74, -51],
        [13, -110],
        [48, -24],
        [65, 16],
        [42, -33],
        [38, -57],
        [91, 34],
        [146, -45],
        [28, 23],
        [0, 57],
        [65, 33],
        [79, 21],
        [-27, -79],
        [81, 6],
        [13, -15],
        [-6, -91],
        [87, 44],
        [61, -54],
        [93, -9],
      ],
      [
        [4778, 7013],
        [13, -49],
        [-104, -15],
        [66, 64],
        [25, 0],
      ],
      [
        [4810, 9301],
        [49, -61],
        [-2, -184],
        [40, -49],
        [72, -32],
        [147, -81],
        [33, -36],
        [24, -78],
        [2, -138],
        [17, -44],
        [0, -87],
        [17, -32],
        [124, -60],
        [48, -32],
        [33, 46],
        [75, 3],
        [77, 62],
        [71, 79],
        [91, 74],
        [82, 52],
        [69, 1],
        [116, 32],
        [128, 0],
        [68, -38],
        [76, -65],
        [175, -187],
        [28, -22],
        [92, -2],
        [12, -14],
        [129, -18],
        [78, 50],
        [120, 106],
      ],
      [
        [6901, 8546],
        [10, -62],
        [-2, -218],
        [-20, -93],
        [-29, -65],
        [-42, -37],
        [-122, -66],
        [-39, 25],
        [-32, -13],
        [-18, -52],
        [-40, -5],
        [-16, -76],
        [-18, -219],
        [-5, -139],
        [-40, -194],
        [-59, -143],
        [-72, -195],
      ],
      [
        [6357, 6994],
        [-35, -105],
        [-127, -79],
        [-154, 12],
        [-117, 61],
        [-36, 0],
        [-111, -34],
        [-152, -78],
      ],
      [
        [5625, 6771],
        [-27, 17],
        [-105, -5],
        [-94, 17],
        [-90, 37],
        [-57, 52],
        [-64, 1],
        [-70, -44],
        [-37, -48],
        [-52, 32],
        [-47, -8],
        [-30, 83],
        [21, 48],
        [-26, 29],
        [35, 51],
        [-5, 37],
        [42, 15],
        [-16, 47],
        [-87, 87],
        [72, 33],
        [-18, 38],
        [-78, -50],
        [-54, 9],
        [-72, -43],
        [-14, -173],
        [-72, -57],
        [-15, -35],
        [5, -73],
        [-44, -64],
        [0, -51],
        [-40, 6],
        [-30, -31],
        [-6, -53],
        [-48, -77],
        [-31, -3],
        [-32, -67],
        [-64, -69],
        [-26, 12],
        [-93, -8],
        [-153, 3],
        [-11, -87],
        [-95, -84],
        [-83, 0],
        [-57, 70],
        [72, 74],
        [116, 104],
        [-69, 12],
        [-49, -44],
        [-54, -4],
        [-48, -65],
        [-90, -6],
        [-59, -58],
        [-55, -6],
        [-46, 19],
        [-10, 32],
        [-81, 7],
        [-38, 18],
        [-70, 75],
        [-75, 3],
        [-35, 56],
        [-22, 125],
        [-81, 7],
        [85, -126],
        [-10, -30],
        [-106, -42],
        [-3, -36],
        [-54, -27],
        [-93, 6],
        [-96, -30],
        [-39, 12],
        [-72, -41],
        [26, -41],
        [-26, -112],
        [-31, 31],
        [-70, 9],
        [-10, -39],
        [-66, -40],
        [-67, -14],
        [-36, -24],
        [-132, 33],
        [-88, -76],
        [-148, -5],
        [3, 35],
        [44, 73],
        [60, 33],
        [49, 57],
        [160, 38],
        [29, 23],
        [21, 81],
        [90, 81],
        [81, 31],
        [69, 79],
        [45, 28],
        [39, 83],
        [49, 31],
        [66, 5],
        [4, 104],
        [53, 64],
        [11, 110],
        [57, 4],
        [18, 59],
        [103, 42],
        [63, -4],
        [51, -85],
        [14, 61],
        [93, 22],
        [-3, 116],
        [52, 22],
        [-26, 41],
        [7, 123],
        [81, 56],
        [5, 89],
        [59, -5],
        [9, 57],
        [-50, 41],
        [41, 30],
        [-23, 45],
        [23, 92],
        [53, 54],
        [70, 21],
        [136, 185],
        [-35, 67],
        [-44, -1],
        [-68, -51],
        [-86, -2],
        [-51, 21],
        [-60, -33],
        [-23, 96],
        [44, 24],
        [75, 88],
        [0, 88],
        [52, 78],
        [64, 47],
        [-46, 36],
        [8, 44],
        [62, 93],
        [57, 61],
        [-8, 31],
        [24, 87],
        [146, 114],
        [-2, 32],
        [67, 103],
        [-18, 60],
        [36, 85],
        [53, 46],
        [64, -24],
        [60, -53],
        [132, -18],
        [31, -18],
        [-8, -49],
        [47, -26],
        [39, 12],
        [53, 87],
        [-17, 58],
        [25, 51],
        [71, 10],
        [69, -19],
        [52, 13],
        [42, -40],
        [43, 3],
        [27, -44],
      ],
      [
        [3484, 159],
        [20, -27],
        [-111, -132],
        [-77, 15],
        [20, 62],
        [73, 58],
        [75, 24],
      ],
      [
        [3657, 380],
        [26, -25],
        [-2, -68],
        [18, -40],
        [-111, -98],
        [-27, 54],
        [40, 21],
        [-8, 68],
        [64, 88],
      ],
      [
        [3572, 415],
        [39, -17],
        [-19, -64],
        [-80, 12],
        [14, 52],
        [46, 17],
      ],
      [
        [1441, 1015],
        [14, -56],
        [-62, -38],
        [-91, -33],
        [-65, -55],
        [-18, 21],
        [37, 60],
        [46, 42],
        [75, 45],
        [64, 14],
      ],
      [
        [2562, 1208],
        [-46, -88],
        [-28, 3],
        [-89, -71],
        [-23, 18],
        [-134, -66],
        [-13, 27],
        [-71, 11],
        [19, 84],
        [29, 28],
        [180, 27],
        [52, -27],
        [62, 36],
        [62, 18],
      ],
      [
        [3450, 1527],
        [-7, -51],
        [-40, -68],
        [-77, -30],
        [-7, 38],
        [54, 54],
        [77, 57],
      ],
      [
        [7315, 4750],
        [-11, -150],
        [5, -67],
        [41, -49],
        [130, -126],
        [45, -80],
      ],
      [
        [7525, 4278],
        [-156, -99],
        [-16, -123],
        [29, -83],
        [63, -56],
        [32, -74],
        [143, -259],
        [270, -329],
        [63, -126],
        [45, -70],
        [52, -13],
        [150, -4],
        [26, -45],
      ],
      [
        [8226, 2997],
        [-5, -80],
        [28, -78],
        [0, -49],
        [-77, -56],
        [-32, -81],
        [-79, -51],
        [7, -63],
        [46, 6],
        [70, -60],
        [-22, -37],
        [-208, -42],
        [-73, -3],
        [-98, -54],
        [-59, -76],
        [-3, -59],
        [62, -38],
        [-26, -24],
        [-152, -78],
        [-156, -6],
        [-44, -9],
        [-95, -104],
        [-36, -10],
        [-13, 53],
        [-93, -8],
        [-123, 9],
        [11, 54],
        [-34, 26],
        [-5, 52],
        [85, 36],
        [-26, 56],
        [52, 38],
        [88, 9],
        [14, 72],
        [-53, -2],
        [-9, 36],
        [48, 92],
        [-41, 39],
        [-39, 3],
        [-57, -62],
        [102, 13],
        [10, -31],
        [-32, -42],
        [3, -39],
        [-130, -16],
        [-146, -47],
        [-17, -24],
        [-48, 20],
        [10, 150],
        [16, 41],
        [-1, 88],
        [-61, -37],
        [-79, -2],
        [-70, 62],
        [3, -117],
        [129, -8],
        [47, -62],
        [-2, -106],
        [-16, -57],
        [109, -23],
        [-10, -84],
        [28, -54],
        [55, 3],
        [5, -56],
        [-24, -55],
        [-70, -89],
        [26, -27],
        [1, -68],
        [-45, -123],
        [-49, 13],
        [-28, -37],
        [-14, -62],
        [-124, -13],
        [-20, -66],
        [-62, 16],
        [-34, -18],
        [-11, -60],
        [-57, -3],
        [-28, -36],
        [-109, -44],
        [3, 72],
        [-23, 71],
        [-36, 5],
        [13, -106],
        [-44, -27],
        [-29, -112],
        [-49, -58],
        [33, -75],
        [1, -87],
        [-73, 42],
        [8, 45],
        [-23, 51],
        [-42, 11],
        [-93, -32],
        [-137, -12],
        [-26, 89],
        [-24, -35],
        [-54, 24],
        [-33, -39],
        [-44, 18],
        [-81, 6],
        [70, -61],
        [132, 6],
        [-33, -84],
        [2, -59],
        [-20, -60],
        [5, -113],
        [-26, -39],
        [-37, -7],
        [-96, 18],
        [-2, 42],
        [-82, 13],
        [-60, 50],
        [-127, 9],
        [-18, 90],
        [-72, 23],
        [-6, -50],
        [67, 20],
        [-20, -70],
        [-94, 33],
        [-2, -30],
        [82, -46],
        [-5, -65],
        [-72, -34],
        [5, -103],
        [-21, -24],
        [-167, -64],
        [-3, 93],
        [-107, 72],
        [-106, -35],
        [-20, -34],
        [-135, -39],
        [-49, 13],
        [-41, 73],
        [-71, 22],
        [-7, -30],
        [65, -18],
        [-8, -107],
        [-36, -58],
        [-44, -29],
        [-78, -26],
        [-46, 16],
        [-8, -67],
        [-42, 3],
        [-20, -40],
        [5, -75],
        [-50, -30],
        [-49, 18],
        [-21, 42],
        [-61, -21],
        [-49, 31],
        [-44, -43],
        [-135, -78],
        [-93, -10],
        [5, 97],
        [-39, 6],
        [-26, 55],
        [-49, 11],
        [-21, 75],
        [57, 45],
        [31, 72],
        [-51, 20],
        [-34, 63],
        [-36, -89],
        [-52, -51],
        [-106, -9],
        [-32, -30],
        [-46, -1],
        [-75, 46],
        [7, -75],
        [-95, -48],
        [-86, -59],
        [-92, -13],
        [7, 43],
        [-28, 63],
        [-44, 9],
        [-47, -45],
        [-91, -48],
        [0, -88],
        [-41, -33],
        [-36, -59],
        [-42, 4],
        [-64, -72],
        [-21, 83],
        [-26, -14],
        [-47, -66],
        [-41, -7],
        [-31, 73],
        [16, 79],
        [-49, 10],
        [-11, 35],
        [93, 61],
        [29, 5],
        [56, 57],
        [9, 47],
        [111, 63],
        [41, -9],
        [86, 28],
        [123, 123],
        [6, 59],
        [82, 39],
        [-7, 32],
        [52, 25],
        [51, 48],
        [40, 60],
        [92, 20],
        [-30, 50],
        [-91, -20],
        [-57, -53],
        [-75, -31],
        [-78, -72],
        [-60, 0],
        [-57, -56],
        [-61, -9],
        [-130, -65],
        [-42, -48],
        [-59, 2],
        [-29, -33],
        [-95, -24],
        [-42, 18],
        [91, 70],
        [34, 43],
        [49, 25],
        [34, 75],
        [31, 9],
        [67, 60],
        [77, 32],
        [57, 71],
        [39, 6],
        [47, 48],
        [93, 28],
        [78, 39],
        [29, 29],
        [79, 40],
        [29, 35],
        [34, -4],
        [132, 55],
        [70, 78],
        [69, 0],
        [5, 106],
        [21, 63],
        [-20, 49],
        [-89, -28],
        [-30, 12],
        [-27, 54],
        [-62, 18],
        [9, 81],
        [-57, 6],
        [-6, -86],
        [19, -49],
        [-14, -36],
        [-103, -72],
        [-57, -61],
        [-37, -12],
        [-98, -78],
        [-57, -3],
        [-21, 29],
        [-46, -5],
        [-70, 20],
        [-25, 57],
        [-27, -14],
        [24, -69],
        [-19, -52],
        [-49, 6],
        [-52, -33],
        [-66, -8],
        [-78, -28],
        [-65, 21],
        [-33, -27],
        [-76, -3],
        [-104, -46],
        [-48, -57],
        [7, -61],
        [-28, -38],
        [-114, 2],
        [-114, -103],
        [-52, -10],
        [-34, -36],
        [-36, 48],
        [-51, 36],
        [-37, -29],
        [-74, -7],
        [-34, 19],
        [-65, -39],
        [-5, 68],
        [-47, 32],
        [68, 51],
        [23, -44],
        [122, 62],
        [64, 1],
        [13, 42],
        [-28, 99],
        [-88, 44],
        [-18, 26],
        [44, 58],
        [61, 2],
        [52, -32],
        [58, 50],
        [64, -29],
        [37, 66],
        [49, -12],
        [69, 158],
        [-129, 12],
        [-8, 27],
        [63, 54],
        [105, 66],
        [54, -7],
        [61, 51],
      ],
      [
        [2188, 1757],
        [62, -109],
        [39, -37],
        [51, -18],
        [104, 107],
        [78, 94],
        [35, 21],
        [35, 70],
        [74, 76],
        [186, 99],
        [99, -11],
        [93, -40],
        [63, 3],
        [80, 31],
        [99, 74],
        [146, 158],
        [58, 44],
        [56, 5],
        [99, -23],
        [55, 60],
        [102, 57],
        [79, 100],
        [43, 111],
        [-3, 89],
        [-27, 88],
        [11, 74],
        [51, 73],
        [75, 65],
        [65, 1],
        [95, 20],
        [91, -4],
        [119, 119],
        [0, 57],
        [-35, 45],
        [-91, 45],
        [-51, 49],
        [-77, 97],
        [18, 59],
        [66, 26],
        [7, 76],
        [-59, 124],
        [-14, 71],
        [21, 239],
        [-7, 49],
        [-187, 284],
        [-2, 52],
        [25, 53],
        [111, 125],
        [46, 36],
        [18, 39],
        [-26, 101],
      ],
      [
        [4164, 4781],
        [200, 85],
        [99, 78],
        [161, -4],
        [66, -30],
        [48, -44],
        [135, -2],
        [91, -14],
        [76, 27],
        [66, 74],
        [207, 208],
        [248, 20],
        [153, -13],
        [87, -30],
        [105, -75],
        [102, -107],
        [96, -135],
        [85, -96],
        [72, -70],
        [73, 21],
        [90, 59],
        [172, 6],
        [126, 91],
        [61, 24],
        [87, -37],
        [186, -124],
        [134, 43],
        [125, 14],
      ],
      [
        [6484, 19166],
        [41, -79],
        [-75, -16],
        [-15, 29],
        [49, 66],
      ],
      [
        [6393, 19333],
        [45, -25],
        [-3, -54],
        [-59, 50],
        [17, 29],
      ],
      [
        [6196, 19473],
        [74, -86],
        [64, -29],
        [-13, -38],
        [41, -76],
        [-3, -42],
        [-201, 0],
        [-44, 12],
        [18, 108],
        [33, 20],
        [31, 131],
      ],
      [
        [6473, 19681],
        [27, -40],
        [-36, -41],
        [-44, 53],
        [53, 28],
      ],
      [
        [6712, 19865],
        [31, -21],
        [-5, -64],
        [-63, 20],
        [-7, 35],
        [44, 30],
      ],
      [
        [7253, 20348],
        [54, -38],
        [-26, -59],
        [-46, 49],
        [18, 48],
      ],
      [
        [7061, 20842],
        [72, -32],
        [86, -93],
        [-64, -5],
        [-47, 44],
        [-70, 38],
        [23, 48],
      ],
      [
        [8379, 20364],
        [73, -27],
        [49, 13],
        [18, -54],
        [52, -33],
        [23, 27],
        [44, -48],
        [36, -72],
        [7, -83],
        [-30, -117],
        [35, -57],
        [35, 153],
        [36, 56],
        [-13, 94],
        [-49, 91],
        [-34, 12],
        [-6, 45],
        [-36, 42],
        [53, 33],
        [-14, 108],
        [-56, -2],
        [2, -63],
        [24, -18],
        [-24, -58],
        [42, -75],
        [-45, -24],
        [-49, 28],
        [10, 72],
        [-35, 14],
        [-42, -45],
        [-29, 4],
        [-33, 74],
        [-32, 1],
        [-15, 61],
        [122, 99],
        [-16, 55],
        [10, 68],
        [48, -41],
        [41, 27],
        [25, -48],
        [65, 17],
        [6, 86],
        [38, -16],
        [104, 100],
        [47, -15],
        [23, -100],
        [25, -27],
        [1, -96],
        [36, -24],
        [-16, -95],
        [-47, -52],
        [39, -81],
        [74, -17],
        [30, -31],
        [-3, -50],
        [94, -120],
        [10, -47],
        [62, -44],
        [6, -55],
        [-39, -62],
        [18, -52],
        [-16, -38],
        [-51, -10],
        [-31, -32],
        [-78, -27],
        [-40, -57],
        [11, -45],
        [-47, -33],
        [-49, -3],
        [-15, -47],
        [70, -16],
        [121, 49],
        [23, -45],
        [1, -60],
        [-66, -59],
        [1, -22],
        [-171, -134],
        [8, -20],
        [-52, -72],
        [-10, -90],
        [103, 32],
        [42, 63],
        [33, 82],
        [29, 12],
        [61, 68],
        [25, 9],
        [61, 78],
        [60, 2],
        [-3, 48],
        [67, 75],
        [-36, 44],
        [-52, 13],
        [-23, 80],
        [49, 60],
        [76, 3],
        [83, -122],
        [22, 5],
        [-10, 94],
        [-74, 70],
        [28, 85],
        [43, 44],
        [-12, 110],
        [-37, 15],
        [-26, 85],
        [-64, 71],
        [-19, 57],
        [-79, 77],
        [-14, 93],
        [26, 99],
        [52, 50],
        [-47, 49],
        [57, 50],
        [11, 80],
        [-44, 42],
        [3, 37],
        [74, 17],
        [29, -30],
        [55, -14],
        [23, 84],
        [85, -51],
        [88, 35],
        [62, -8],
        [26, 80],
        [-7, 47],
        [46, 16],
        [67, -25],
        [37, -61],
        [51, -27],
        [13, -32],
        [-59, -17],
        [-47, 14],
        [-25, -37],
        [107, 2],
        [40, -42],
        [46, -5],
        [31, 33],
        [-21, 59],
        [-106, 95],
        [-44, 5],
        [-10, 71],
        [-62, 35],
        [1, 88],
        [18, 56],
        [-76, 12],
        [-44, 39],
        [-31, 57],
        [88, 35],
        [18, -24],
        [86, -35],
        [-1, -24],
        [172, -16],
        [20, -34],
        [81, 1],
        [5, -26],
        [152, -53],
        [52, -45],
        [15, -112],
        [35, -73],
        [-1, -43],
        [108, -38],
        [73, -12],
        [1, -22],
        [79, -53],
        [50, 20],
        [60, -44],
        [56, -5],
        [8, -60],
        [99, 9],
        [48, -12],
        [101, -102],
        [-18, -58],
        [-64, -79],
        [-153, -70],
        [-62, -44],
        [-73, -13],
        [-85, -74],
        [-101, -56],
        [-23, -25],
        [-96, -33],
        [-158, -187],
        [-21, -72],
        [-87, -110],
        [-40, -81],
        [-55, -68],
        [-58, -7],
        [-35, -39],
        [-139, 3],
        [-80, -126],
        [-9, -83],
        [-29, -40],
        [15, -57],
        [-37, -50],
        [21, -55],
        [2, -145],
        [-147, -62],
        [-16, -181],
        [13, -100],
        [-41, -134],
        [-56, -64],
        [-17, -87],
        [-108, -79],
        [-76, -89],
        [-23, -195],
        [48, -78],
        [-70, -23],
        [-33, 34],
        [-198, -17],
        [-26, 12],
        [-106, -106],
        [-147, -88],
        [-46, -44],
        [-246, 178],
        [-40, -28],
        [-67, -136],
        [-39, -24],
        [-121, -8],
        [-3, -34],
        [63, -38],
        [-26, -96],
        [70, -79],
        [81, -18],
        [21, -100],
        [79, 3],
        [55, 20],
        [90, -38],
        [67, -66],
        [54, 2],
        [79, 55],
        [14, -130],
        [-97, -33],
        [-77, -79],
        [-66, -23],
        [-16, -44],
        [-77, -58],
        [-7, -74],
        [-22, -22],
        [-288, -48],
        [-114, 40],
        [-85, 1],
        [-23, 14],
        [-124, -189],
        [-5, -90],
        [-60, -31],
        [-49, -50],
        [-68, -15],
        [-20, -42],
        [-69, 10],
        [-90, -20],
        [20, -51],
      ],
      [
        [7311, 16411],
        [-251, 4],
        [-58, 51],
        [-44, 65],
      ],
      [
        [6958, 16531],
        [8, 52],
        [70, 56],
        [23, 47],
        [-67, 87],
        [114, 102],
        [62, 29],
        [-4, 49],
        [-33, 77],
        [45, 6],
        [35, 52],
        [50, 20],
        [38, 45],
        [10, 59],
        [79, 42],
        [40, 94],
        [-5, 73],
        [-28, -2],
        [-56, -53],
        [-58, 44],
        [-46, -12],
        [-28, -36],
        [-68, -30],
        [-37, -54],
        [-40, -2],
        [-84, -66],
        [-44, -6],
        [-12, 51],
        [35, 95],
        [31, 21],
        [-36, 62],
        [-83, -60],
        [-87, -29],
        [-29, -81],
        [-73, -60],
        [-44, -61],
        [-109, -34],
        [-27, -72],
        [-55, 10],
        [39, 74],
        [134, 54],
        [48, 71],
        [4, 68],
        [-26, 38],
        [-47, 0],
        [-43, -81],
        [-62, 38],
        [-52, -59],
        [-13, -40],
        [-64, -6],
        [12, 153],
        [-16, 15],
        [-137, -86],
        [-69, 0],
        [-13, -22],
        [-138, 9],
        [-69, 25],
        [-138, -13],
        [-36, 120],
        [-59, 9],
        [-45, 35],
        [-46, 4],
        [-135, 48],
        [-20, 30],
        [26, 51],
        [-1, 72],
        [-36, 11],
        [-26, 45],
        [24, 45],
        [64, -10],
        [79, 42],
        [-1, 86],
        [63, 78],
        [72, 18],
        [34, 67],
        [52, 18],
        [14, 55],
        [37, -7],
        [116, 40],
        [80, 12],
        [128, -49],
        [33, -33],
        [50, 18],
        [20, -35],
        [50, 4],
        [72, -33],
        [8, 56],
        [-141, 29],
        [-93, 58],
        [-2, 40],
        [47, 6],
        [41, -31],
        [46, 25],
        [96, -1],
        [-2, -41],
        [140, -105],
        [-8, 80],
        [-36, -12],
        [-57, 126],
        [-62, 21],
        [-47, -24],
        [-3, 55],
        [-98, 20],
        [3, 48],
        [-114, 31],
        [22, 37],
        [81, 7],
        [50, -18],
        [10, 47],
        [62, 47],
        [40, -4],
        [40, -37],
        [39, -6],
        [90, 40],
        [76, -16],
        [92, -48],
        [48, 5],
        [-35, 97],
        [-75, -17],
        [0, 158],
        [37, -21],
        [33, 36],
        [98, -16],
        [12, 40],
        [-63, 21],
        [-10, 86],
        [-39, -39],
        [-46, 11],
        [5, -79],
        [-49, 6],
        [-125, 69],
        [-65, 46],
        [-13, 78],
        [0, 97],
        [71, 23],
        [12, -32],
        [65, -22],
        [23, 25],
        [63, -1],
        [39, 37],
        [-120, 61],
        [-166, 218],
        [21, 48],
        [99, -3],
        [28, 24],
        [-44, 70],
        [24, 47],
        [38, 6],
        [47, 42],
        [126, -20],
        [16, 35],
        [-21, 99],
        [57, 22],
        [50, -41],
        [-14, 95],
        [-26, 31],
        [40, 87],
        [-11, 68],
        [67, 99],
        [-10, 108],
        [44, 53],
        [28, -21],
        [120, -30],
        [41, 12],
        [24, -30],
        [95, 10],
        [54, -15],
        [34, 90],
        [34, 2],
        [10, -51],
        [-67, -83],
        [-23, -66],
        [92, 30],
        [27, 35],
        [5, 106],
        [155, 24],
        [46, 62],
        [94, 15],
        [13, 42],
        [-24, 32],
        [9, 99],
        [22, 37],
        [39, 3],
        [47, 62],
        [31, -17],
        [18, -61],
        [73, 6],
        [12, -56],
        [-56, -84],
        [31, -37],
        [87, 22],
        [63, 2],
        [-13, -60],
        [65, -11],
        [-16, -43],
        [90, 5],
        [-33, -82],
        [-85, -24],
        [-28, -62],
        [133, 41],
        [58, 43],
        [34, 102],
        [4, 70],
        [-26, 58],
        [-79, -3],
        [-31, 41],
        [4, 114],
        [19, 37],
        [137, -11],
        [-18, 60],
        [44, 3],
        [21, 64],
        [59, 19],
        [-26, -70],
        [5, -43],
        [-49, -74],
        [-26, -107],
        [47, -55],
      ],
      [
        [13312, 10594],
        [-78, -67],
        [-75, -94],
        [-13, 55],
        [29, 38],
        [78, 53],
        [59, 15],
      ],
      [
        [13574, 11224],
        [43, -2],
        [26, -35],
        [-18, -54],
        [-43, 5],
        [-23, 43],
        [15, 43],
      ],
      [
        [13343, 9710],
        [-43, -31],
        [-77, -21],
        [-172, -1],
        [-78, 39],
        [-50, 3],
        [-267, -89],
        [-25, 0],
        [-111, 89],
        [-57, 35],
        [-52, 2],
        [-121, 26],
      ],
      [
        [12290, 9762],
        [-117, 60],
        [-91, 83],
        [-17, 177],
        [71, 113],
        [40, 83],
        [38, 144],
        [0, 131],
        [-16, 49],
      ],
      [
        [12198, 10602],
        [123, -39],
        [66, 95],
        [48, 92],
        [137, 172],
        [97, 49],
        [42, 180],
        [0, 53],
        [-37, 86],
        [-104, 127],
        [-69, 106],
        [-46, 49],
        [0, 45],
        [44, 126],
        [40, 41],
        [110, -74],
        [149, -49],
        [33, 148],
        [44, 73],
        [150, 80],
      ],
      [
        [13025, 11962],
        [73, -26],
        [-1, -54],
        [19, -45],
        [49, -23],
        [38, -105],
        [71, -15],
        [72, -44],
        [21, -70],
        [43, -27],
        [11, -56],
        [-39, -170],
        [-38, -39],
        [-55, 17],
        [-72, -62],
        [61, -23],
        [37, 39],
        [21, -67],
        [-10, -57],
        [-35, -66],
        [-31, -94],
        [-35, 6],
        [33, 85],
        [-34, 28],
        [-25, -36],
        [-62, 2],
        [-39, -47],
        [87, -39],
        [58, -11],
        [49, -44],
        [2, -116],
        [29, -94],
        [-3, -64],
        [127, -5],
        [65, -27],
        [12, -57],
        [-39, -63],
        [-71, -18],
        [-50, 54],
        [-7, 78],
        [-45, 20],
        [-44, -6],
        [-62, -35],
        [-83, -111],
        [-49, -41],
        [45, -83],
        [-81, -31],
        [13, -87],
        [86, -77],
        [66, -34],
        [62, 4],
        [27, -46],
        [56, -15],
        [31, -49],
        [-56, -99],
        [2, -99],
        [18, -108],
      ],
      [
        [3306, 8897],
        [70, -35],
        [-27, -66],
        [-59, -30],
        [-72, 24],
        [28, 72],
        [60, 35],
      ],
      [
        [3171, 9080],
        [33, -32],
        [1, -60],
        [-26, -19],
        [-24, -62],
        [-85, -36],
        [-37, 21],
        [8, 62],
        [80, 119],
        [50, 7],
      ],
      [
        [2562, 9346],
        [63, -22],
        [28, -95],
        [39, 40],
        [142, 6],
        [50, -40],
        [46, -120],
        [54, -57],
        [-4, -53],
        [-78, 16],
        [-78, 71],
        [-49, 9],
        [-28, 33],
        [-71, 20],
        [-101, 64],
        [-158, 59],
        [24, 41],
        [59, 28],
        [62, 0],
      ],
      [
        [2751, 10068],
        [78, -58],
        [-96, 3],
        [18, 55],
      ],
      [
        [3002, 10258],
        [-23, -142],
        [-33, -50],
        [18, -111],
        [5, -140],
        [-11, -53],
        [-57, -31],
        [-111, 10],
        [-44, 15],
        [-98, 6],
        [-26, 61],
        [2, 141],
        [45, -24],
        [31, -66],
        [51, -26],
        [-26, 104],
        [123, 37],
        [-1, 50],
        [-85, 35],
        [15, 93],
        [62, 33],
        [76, -36],
        [31, 75],
        [56, 19],
      ],
      [
        [2910, 10311],
        [7, -62],
        [-72, -27],
        [-3, 38],
        [54, 9],
        [14, 42],
      ],
      [
        [1445, 11441],
        [43, -73],
        [-49, -17],
        [-36, 17],
        [-10, 57],
        [52, 16],
      ],
      [
        [1082, 11874],
        [52, -33],
        [-1, -51],
        [-61, 8],
        [10, 76],
      ],
      [
        [1326, 11973],
        [72, -33],
        [-32, -37],
        [35, -41],
        [-44, -60],
        [-132, 38],
        [-65, 28],
        [100, 65],
        [44, -9],
        [22, 49],
      ],
      [
        [7726, 9938],
        [53, -23],
        [122, -174],
        [-2, -77],
        [-250, -137],
        [-64, -53],
      ],
      [
        [7585, 9474],
        [-178, -148],
        [-153, -217],
        [-72, -180],
        [-155, -226],
        [-126, -157],
      ],
      [
        [4810, 9301],
        [12, 42],
        [-38, 36],
        [-99, 15],
        [-62, -15],
        [-10, 57],
        [57, -12],
        [100, 21],
        [122, -1],
        [59, 33],
        [-20, -86],
        [41, -1],
        [18, -35],
        [40, 11],
        [-19, 72],
        [-43, 25],
        [31, 50],
        [12, 93],
        [41, 32],
        [-31, 30],
        [-61, -18],
        [-27, 24],
        [-87, -9],
        [-130, 27],
        [-15, 22],
        [88, 35],
        [101, 12],
        [-6, 47],
        [34, 102],
        [78, 7],
        [41, 77],
        [-104, -12],
        [-18, -30],
        [-49, -14],
        [-8, 36],
        [-65, -18],
        [-71, 15],
        [-37, -31],
        [-99, -29],
        [-56, -28],
        [-150, -33],
        [-140, 6],
        [-39, 12],
        [-148, -35],
        [-105, -1],
        [-16, -20],
        [-78, -4],
        [-62, 22],
        [-119, -54],
        [-155, 1],
        [-31, -37],
        [-68, 15],
        [-61, -38],
        [-52, 3],
        [-19, 41],
        [-49, 32],
        [-2, 55],
        [-26, 29],
        [-26, 75],
        [36, 25],
        [8, 42],
        [-31, 23],
        [-47, -53],
        [21, -46],
        [-11, -93],
        [-44, -61],
        [-39, 20],
        [-28, -38],
        [-33, 38],
        [-19, 68],
        [13, 46],
        [58, 95],
        [-6, 68],
        [-28, 22],
        [30, 44],
        [-46, 90],
        [8, 47],
        [104, 18],
        [51, 28],
        [-47, 92],
        [-39, 46],
        [9, 110],
        [-50, -6],
        [32, -98],
        [5, -79],
        [41, 4],
        [2, -55],
        [-43, -24],
        [-130, -2],
        [-69, 29],
        [54, 36],
        [8, 37],
        [-71, -13],
        [-13, 42],
        [96, -9],
        [21, 54],
        [-21, 84],
        [-31, 29],
        [-23, -74],
        [-91, -63],
        [-15, -51],
        [-62, -123],
        [-37, -32],
        [-12, -89],
        [-80, -4],
        [-76, -36],
        [-80, 60],
        [-83, -14],
        [21, -93],
        [-44, 8],
        [2, 40],
        [-74, 20],
        [-21, 49],
        [-47, 33],
        [-25, 55],
        [20, 52],
        [31, 26],
        [-15, 76],
        [22, 65],
        [65, 1],
        [132, 62],
        [84, 62],
        [-70, 51],
        [-31, 84],
        [-48, -113],
        [-49, -34],
        [-66, 19],
        [-94, -22],
        [-57, -53],
        [-75, -3],
        [-39, -50],
        [-31, 53],
        [-101, 12],
        [-56, 47],
        [5, 93],
        [-24, 22],
        [-96, 37],
        [-136, -88],
        [-50, 45],
        [-5, 33],
        [-104, -28],
        [3, 39],
        [36, 54],
        [-3, 86],
        [40, 13],
        [41, 50],
        [77, -17],
        [138, -54],
        [28, 84],
        [-59, 43],
        [-50, 6],
        [8, 46],
        [-29, 42],
        [50, 32],
        [-18, 36],
        [-65, 1],
        [-36, 70],
        [-50, 3],
        [6, 96],
        [-24, 67],
        [-70, 14],
        [-31, -14],
        [-66, 27],
        [41, 70],
        [59, 30],
        [70, -3],
        [28, -26],
        [39, 17],
        [39, -29],
        [50, 0],
        [-88, 80],
        [-22, 34],
        [84, 3],
        [79, -13],
        [32, -26],
        [140, -12],
        [-18, -34],
        [56, -71],
        [34, 0],
        [19, 128],
        [-104, 24],
        [-83, 99],
        [-6, 75],
        [42, 15],
        [57, -25],
        [59, 25],
        [94, -60],
        [70, -9],
        [77, 12],
        [36, 47],
        [63, 18],
        [28, 37],
        [81, -12],
        [33, -34],
        [68, -29],
        [57, -65],
        [172, 35],
        [91, -23],
        [37, 62],
        [48, 40],
      ],
      [
        [2888, 11851],
        [21, 28],
        [105, 68],
        [47, 0],
        [161, -100],
        [95, -89],
        [185, -160],
        [72, -87],
        [76, -60],
        [61, -32],
        [137, 12],
        [46, 37],
        [102, 13],
        [101, -21],
        [41, -128],
        [41, -79],
        [77, -92],
        [157, -7],
        [96, 3],
        [39, 17],
        [96, 118],
        [48, 118],
        [7, 63],
        [95, 103],
        [50, 96],
        [57, 216],
        [94, 60],
        [75, 69],
        [187, 6],
        [138, 89],
      ],
      [
        [5395, 12112],
        [21, -22],
        [66, -5],
        [108, 9],
        [131, 37],
        [115, 22],
        [60, 50],
        [49, 128],
        [47, 31],
        [81, 5],
        [261, -9],
        [29, -37],
        [74, -22],
        [62, -55],
        [88, -145],
        [69, -82],
        [40, -161],
        [5, -91],
        [41, 14],
        [133, -114],
        [77, -163],
        [61, -186],
        [32, -146],
        [24, -296],
        [12, -85],
        [22, -40],
        [55, 0],
        [13, -102],
        [-19, -63],
        [-37, -28],
        [-40, -79],
        [19, -29],
        [83, -45],
        [117, -97],
        [43, -5],
        [18, -51],
        [10, -159],
        [40, -148],
        [80, -17],
        [116, 6],
        [96, -12],
        [29, 18],
      ],
      [
        [1196, 1688],
        [31, -14],
        [-34, -54],
        [-47, 29],
        [50, 39],
      ],
      [
        [895, 2766],
        [93, -63],
        [66, 22],
        [25, -10],
        [-13, -71],
        [-54, -47],
        [-82, -31],
        [-52, -47],
        [-80, -16],
        [-40, 21],
        [-38, -35],
        [-63, -27],
        [16, 89],
        [52, 91],
        [72, 5],
        [93, 65],
        [5, 54],
      ],
      [
        [399, 3714],
        [34, -39],
        [-26, -50],
        [-66, -46],
        [-90, -2],
        [57, 51],
        [42, 12],
        [49, 74],
      ],
      [
        [3889, 6211],
        [-26, -126],
        [28, -85],
        [72, -85],
        [-24, -48],
        [-74, -40],
        [-27, -85],
        [0, -85],
        [16, -61],
        [107, -41],
        [4, -41],
        [-43, -68],
        [-2, -74],
        [-27, -160],
        [-33, -41],
        [9, -88],
        [37, -14],
        [129, -125],
        [129, -163],
      ],
      [
        [2188, 1757],
        [15, 27],
        [67, 48],
        [-7, -90],
        [82, 30],
        [-2, 69],
        [23, 71],
        [42, -17],
        [-8, -48],
        [101, 12],
        [-19, 65],
        [-54, 24],
        [55, 47],
        [79, 36],
        [1, 39],
        [67, 60],
        [38, 15],
        [1, 42],
        [59, 50],
        [235, 37],
        [47, 63],
        [47, 23],
        [111, 82],
        [-160, -52],
        [-76, 16],
        [-97, -54],
        [-99, -30],
        [-88, -49],
        [-60, -21],
        [-56, -86],
        [-50, -45],
        [-28, 67],
        [-32, -22],
        [-2, -51],
        [-33, -30],
        [-89, -20],
        [-12, 41],
        [-70, -59],
        [-80, 6],
        [-70, -21],
        [-60, -66],
        [-57, -23],
        [-72, -79],
        [-60, 31],
        [-39, -19],
        [-44, -71],
        [-51, 8],
        [-133, -90],
        [-46, -10],
        [88, 115],
        [-86, -17],
        [-74, 44],
        [-40, -9],
        [-59, 61],
        [3, 30],
        [51, 31],
        [10, 231],
        [-35, 11],
        [-60, 61],
        [-98, -3],
        [-27, -64],
        [-59, -52],
        [5, -82],
        [-83, -33],
        [-25, -41],
        [-98, -57],
        [-40, 78],
        [58, 38],
        [-16, 43],
        [16, 163],
        [-68, 6],
        [-49, -15],
        [-60, 9],
        [27, 73],
        [-24, 143],
        [68, 18],
        [64, -4],
        [34, 33],
        [46, 0],
        [39, 39],
        [63, 27],
        [28, 48],
        [62, 10],
        [3, 52],
        [-47, 51],
        [75, 39],
        [20, 34],
        [40, 12],
        [121, 65],
        [-24, 25],
        [-54, -30],
        [-48, -52],
        [-44, -6],
        [-78, -57],
        [-11, 24],
        [-67, 39],
        [-73, 7],
        [37, 60],
        [82, 41],
        [19, -26],
        [39, 110],
        [92, 56],
        [39, -29],
        [24, 65],
        [43, 37],
        [86, 25],
        [23, 37],
        [73, 45],
        [73, -22],
        [41, 42],
        [108, 39],
        [76, -18],
        [147, 85],
        [13, 28],
        [72, 52],
        [47, 83],
        [16, 61],
        [51, 35],
        [26, 74],
        [36, -14],
        [78, 18],
        [88, 66],
        [133, 26],
        [-30, 61],
        [26, 52],
        [-28, 37],
        [-132, -18],
        [-64, 15],
        [-13, -22],
        [-141, -26],
        [-53, -24],
        [-40, 3],
        [-38, -38],
        [41, -27],
        [18, -39],
        [-44, -64],
        [-69, 144],
        [-172, -38],
        [-30, 3],
        [-128, -70],
        [-41, 18],
        [-54, -68],
        [-42, 29],
        [-57, 6],
        [-30, -33],
        [-76, -38],
        [-25, 69],
        [-29, 14],
        [-104, -30],
        [-35, 64],
        [-40, 42],
        [-77, -7],
        [-1, -38],
        [105, -21],
        [-4, -60],
        [-62, -16],
        [-46, 24],
        [-42, -8],
        [-26, 47],
        [-105, 58],
        [-36, -43],
        [38, -50],
        [16, -58],
        [-104, 7],
        [-93, -41],
        [-93, -4],
        [-3, 47],
        [-41, 7],
        [33, 89],
        [-30, 51],
        [15, 138],
        [39, 50],
        [-10, 55],
        [-50, 18],
        [73, 59],
        [38, 10],
        [32, 53],
        [57, 9],
        [-31, -80],
        [4, -43],
        [110, -47],
        [31, 47],
        [-39, 45],
        [10, 53],
        [55, 58],
        [-48, 17],
        [0, 61],
        [55, 42],
        [90, 17],
        [50, 21],
        [46, 99],
        [57, 0],
        [27, 56],
        [51, 31],
        [104, 29],
        [96, 59],
        [61, -23],
        [-10, -107],
        [19, -57],
        [-58, -114],
        [45, 18],
        [56, -9],
        [15, 33],
        [89, 5],
        [51, 22],
        [122, 123],
        [34, 80],
        [-3, 51],
        [42, 29],
        [10, -104],
        [-33, -56],
        [83, -114],
        [49, -19],
        [75, -77],
        [54, -8],
        [47, 18],
        [31, -28],
        [56, 4],
        [114, 51],
        [32, 53],
        [64, -25],
        [47, -2],
        [57, 44],
        [49, -14],
        [57, 36],
        [-16, 59],
        [-41, 7],
        [-29, 35],
        [-41, -20],
        [-78, 8],
        [-96, 37],
        [-39, -27],
        [-70, 113],
        [35, 41],
        [36, -72],
        [72, 6],
        [24, 55],
        [-75, -18],
        [-35, 26],
        [83, 61],
        [40, 2],
        [-6, 48],
        [-28, 30],
        [13, 62],
        [-3, 132],
        [11, 80],
        [-13, 51],
        [-93, 13],
        [-104, 29],
        [-32, -6],
        [-80, 48],
        [-18, 30],
        [11, 71],
        [70, 46],
        [134, 3],
        [24, -25],
        [59, 31],
        [55, 9],
        [44, 33],
        [80, 29],
        [105, 75],
        [24, 60],
        [150, 63],
        [31, 75],
        [-8, 178],
        [36, 61],
        [-36, 23],
        [50, 86],
        [87, 64],
        [44, 47],
        [104, -35],
        [88, -46],
        [47, -3],
        [57, 40],
        [78, 32],
        [17, -87],
        [24, -15],
        [77, 114],
        [60, 18],
        [64, -26],
        [143, 53],
        [-11, -96],
        [108, 3],
      ],
      [
        [12290, 9762],
        [-108, -203],
        [-44, -65],
        [-117, -140],
        [-29, -191],
        [-79, -138],
        [-67, -67],
        [-83, -64],
        [-146, -6],
        [-29, -29],
        [-7, -116],
        [-18, -72],
        [-31, -34],
        [-67, -18],
        [-10, -28],
        [6, -205],
        [32, -94],
        [46, -54],
        [23, -124],
      ],
      [
        [10965, 7748],
        [10, 20],
        [2, 223],
        [-15, 73],
        [-78, 202],
        [-42, 81],
        [-46, 42],
        [-67, -33],
        [-78, 0],
        [-29, 15],
        [-21, 72],
        [0, 226],
        [24, 108],
        [3, 180],
        [-84, 243],
        [-13, 150],
        [-30, 38],
        [-68, 26],
      ],
      [
        [10433, 9414],
        [-40, 90],
        [-1, 42],
        [112, 8],
        [74, 38],
        [23, 28],
        [-3, 137],
        [-47, 37],
        [-3, 42],
        [42, 28],
        [8, 64],
        [-18, 62],
        [13, 33],
        [60, 14],
        [85, 95],
        [10, 76],
        [-79, 112],
        [-18, 81],
        [-36, 66],
        [-66, 36],
        [-97, 85],
        [-56, 81],
        [-77, 70],
      ],
      [
        [10319, 10739],
        [123, 167],
        [84, 102],
        [39, 9],
        [44, 269],
        [15, 19],
      ],
      [
        [10624, 11305],
        [79, -80],
        [166, -119],
        [78, -81],
        [170, -331],
        [69, -58],
        [108, -4],
        [54, 25],
        [59, 77],
        [29, 96],
        [122, 0],
        [244, -20],
        [93, -118],
        [158, -82],
        [100, 8],
        [45, -16],
      ],
      [
        [10964, 5527],
        [-21, -103],
        [-54, -203],
        [-95, -209],
        [-11, -46],
        [8, -276],
      ],
      [
        [10791, 4690],
        [-69, -109],
        [-38, -22],
        [-290, -16],
        [-171, -53],
        [-67, -15],
        [-82, 50],
        [-149, 131],
        [-119, 161],
        [-81, 149],
      ],
      [
        [9725, 4966],
        [8, 47],
        [-14, 71],
        [-2, 178],
        [-133, 219],
        [-39, 96],
        [-4, 93],
        [34, 82],
        [9, 79],
        [-62, 299],
        [-71, 207],
        [-65, 240],
        [-53, 93],
        [-42, 102],
        [-39, 70],
        [-46, 41],
        [-128, 85],
        [-56, 64],
        [-99, 136],
        [-109, 223],
      ],
      [
        [8814, 7391],
        [25, 31],
        [97, 75],
        [95, 117],
        [47, 15],
        [75, -84],
        [47, -69],
        [95, -6],
        [99, -41],
        [28, -30],
        [80, 0],
        [114, 102],
        [86, 60],
        [54, 25],
        [29, 39],
        [17, 75],
        [81, 96],
        [35, 23],
        [80, 0],
        [71, 33],
        [6, 72],
        [112, 22],
        [69, -7],
        [110, -77],
        [86, -143],
        [41, -105],
        [32, -49],
      ],
      [
        [8814, 7391],
        [-42, 95],
        [-68, 233],
        [-1, 161],
        [13, 67],
        [72, 99],
        [3, 149],
        [-19, 89],
        [-84, 144],
      ],
      [
        [8688, 8428],
        [11, 56],
        [36, 42],
        [66, 112],
        [54, 123],
        [55, 94],
        [123, 112],
        [57, 98],
        [-29, 49],
        [-62, 11],
        [-48, 49],
        [-47, 17],
        [2, 115],
        [79, 109],
        [137, 65],
        [8, 24],
        [73, 0],
        [37, 42],
        [164, -7],
        [135, 4],
        [36, 73],
        [103, 4],
        [119, -48],
        [83, -115],
        [40, -130],
        [34, -45],
        [124, 98],
        [43, 21],
        [167, 3],
        [144, -10],
        [1, 20],
      ],
      [
        [7311, 16411],
        [69, -15],
        [-28, -95],
        [163, -156],
        [145, -234],
        [65, -38],
        [50, 24],
        [97, -123],
        [75, -164],
        [-24, -17],
      ],
      [
        [8939, 13595],
        [-114, 10],
        [-52, -11],
        [-30, -45],
        [-40, -113],
        [-70, -107],
        [-55, -62],
        [-116, -33],
        [-21, -113],
        [-22, -62],
        [-113, -101],
        [-79, -12],
        [-101, 6],
        [-45, -16],
      ],
      [
        [8081, 12936],
        [-19, 185],
        [-31, 101],
        [-42, 40],
        [-61, 11],
        [-43, 118],
        [-101, 147],
        [-78, 17],
        [-62, -4],
        [-81, 15],
        [-41, 50],
        [-35, 94],
        [84, 126],
        [10, 54],
        [-6, 274],
        [16, 36],
        [64, 51],
        [10, 101],
        [-16, 93],
        [-19, 33],
        [-160, -1],
        [-121, 15],
        [-65, -2],
      ],
      [
        [7284, 14490],
        [-72, 111],
        [-80, 67],
        [-81, 36],
        [-89, 11],
        [-63, 61],
        [-62, 90],
        [-30, 72],
        [-9, 161],
        [9, 78],
        [67, 201],
        [-17, 87],
        [-167, 309],
        [-13, 46],
        [2, 83],
        [53, 281],
        [-47, -10],
        [-63, -37],
        [-6, 107],
        [70, 101],
        [13, 74],
        [-10, 49],
      ],
      [
        [6689, 16468],
        [116, 19],
        [55, 42],
        [98, 2],
      ],
      [
        [3889, 6211],
        [115, -3],
        [171, 118],
        [86, 6],
        [39, 41],
        [127, 30],
        [58, 41],
        [47, -12],
        [60, 13],
        [52, 45],
        [34, 71],
        [33, -54],
        [70, -8],
        [95, 68],
        [6, 40],
        [93, 80],
        [85, -10],
        [99, 9],
        [60, 46],
        [168, 8],
        [28, -56],
        [103, 41],
        [60, 39],
        [47, 7],
      ],
      [
        [6357, 6994],
        [53, -61],
        [161, -84],
        [163, -43],
        [102, 43],
        [118, 10],
        [30, 26],
        [77, -12],
        [59, -98],
        [38, -37],
        [72, -5],
        [39, -54],
        [-10, -126],
        [-36, -128],
        [2, -339],
        [-5, -95],
        [-16, -58],
        [-80, -50],
        [-117, -11],
        [-105, -103],
        [-35, -2],
        [-80, 46],
        [-78, -41],
        [-82, -84],
        [-13, -41],
        [30, -161],
        [103, -23],
        [176, -64],
        [103, -47],
        [84, -52],
        [45, -68],
        [13, -70],
        [33, -46],
        [106, -100],
        [21, -52],
        [-13, -214],
      ],
      [
        [9561, 12753],
        [29, -62],
        [14, -68],
        [-32, -118],
        [-49, -35],
        [-43, 0],
        [-94, -71],
        [-82, -118],
        [-118, -109],
        [-83, -46],
        [-88, -93],
        [-39, -26],
        [4, -107],
        [15, -33],
        [2, -87],
        [-53, -178],
        [-157, -205],
        [-153, -2],
        [-89, 7],
        [-145, -2],
        [-68, 37],
        [-143, -9],
        [-32, -31],
        [-65, -2],
        [-64, -37],
        [-61, -15],
        [-43, -47],
      ],
      [
        [7924, 11296],
        [-8, 32],
        [0, 136],
        [-25, 64],
        [-86, 95],
        [-59, 82],
        [-37, 73],
        [0, 31],
        [57, 187],
        [22, 129],
        [59, 159],
        [111, 191],
        [64, 127],
        [56, 45],
        [37, 55],
        [8, 91],
        [-42, 143],
      ],
      [
        [12952, 12737],
        [-51, -9],
        [-60, -66],
        [-58, -122],
        [-78, -78],
        [-48, -28],
        [-234, -5],
        [-184, 5],
        [-12, 28],
        [0, 138],
        [-12, 51],
        [-84, 0],
        [-76, 41],
        [-51, 6],
        [-73, 166],
        [-51, 173],
        [-17, 108],
        [-19, 40],
        [-208, 75],
        [-106, 75],
        [-54, 16],
        [-34, 44],
      ],
      [
        [11442, 13404],
        [45, 3],
        [34, -26],
        [52, 36],
        [85, 27],
        [30, 44],
        [80, 275],
        [59, 99],
        [108, 3],
        [25, 27],
        [20, 73],
        [-6, 140],
        [-37, 110],
      ],
      [
        [11937, 14215],
        [50, -5],
        [112, 50],
        [75, -19],
        [92, 55],
        [6, 65],
        [64, -45],
        [14, -83],
        [202, 60],
        [52, 43],
        [20, 47],
        [-23, 137],
        [25, 39],
        [47, -9],
        [49, -100],
        [77, 75],
        [29, 5],
        [83, -121],
        [24, -62],
        [124, -81],
        [36, -47],
        [18, -68],
        [33, 0],
        [57, -63],
        [68, 17],
        [2, -59],
        [76, -107],
        [-24, -33],
        [-152, -96],
        [-104, 14],
        [-88, 39],
        [-64, 4],
        [-57, 38],
        [-73, 72],
        [-103, 26],
        [-30, -35],
        [22, -71],
        [-83, -73],
        [0, -59],
        [-26, -125],
        [5, -135],
        [-16, -33],
        [31, -99],
        [76, -96],
        [25, -15],
        [63, 18],
        [57, -45],
        [59, -2],
        [28, -36],
        [57, -11],
        [-35, -60],
        [-30, -112],
        [1, -128],
        [46, -35],
        [70, -6],
        [-41, -54],
        [-11, -54],
      ],
      [
        [1654, 12394],
        [8, -91],
        [-42, -26],
        [-96, -24],
        [-18, 23],
        [37, 88],
        [70, 5],
        [41, 25],
      ],
      [
        [1978, 13011],
        [51, -91],
        [58, -3],
        [-45, -93],
        [-96, -28],
        [-165, 0],
        [-10, 70],
        [51, 39],
        [58, 8],
        [53, 27],
        [45, 71],
      ],
      [
        [2027, 13248],
        [59, -24],
        [0, -63],
        [-75, 43],
        [16, 44],
      ],
      [
        [2324, 13915],
        [26, -70],
        [-43, -39],
        [-19, 40],
        [36, 69],
      ],
      [
        [1294, 14616],
        [36, -32],
        [-15, -42],
        [-91, -1],
        [70, 75],
      ],
      [
        [1338, 14735],
        [44, -61],
        [-56, -64],
        [-26, 31],
        [-1, 91],
        [39, 3],
      ],
      [
        [4447, 14641],
        [156, 76],
        [132, 39],
        [224, -34],
        [19, -49],
        [-11, -180],
        [-60, -122],
        [-127, -176],
        [-8, -97],
        [116, 2],
        [57, -27],
        [44, -43],
        [116, 0],
        [64, -42],
        [76, -117],
        [91, -86],
        [40, 0],
        [40, 50],
        [74, 36],
        [53, 0],
        [302, 202],
        [144, -69],
        [46, -50],
        [18, -50],
        [0, -142],
      ],
      [
        [6053, 13762],
        [-25, -10],
        [-198, -4],
        [-47, -37],
        [-9, -53],
        [98, -111],
        [105, -136],
        [24, -49],
        [-15, -91],
        [-45, -28],
        [-138, 16],
        [-42, -4],
        [-18, -57],
        [0, -74],
        [-54, -169],
        [65, -94],
        [40, -87],
        [7, -102],
        [-47, -70],
        [-11, -41],
        [0, -127],
        [-25, -42],
        [-93, -57],
        [-38, -61],
        [-115, -68],
        [-77, -94],
      ],
      [
        [2888, 11851],
        [-82, -59],
        [-109, -11],
        [-85, 6],
        [-24, -13],
        [-62, 28],
        [-132, 92],
        [-62, 14],
        [-101, 124],
        [-60, 21],
        [3, 72],
        [23, 24],
        [-9, 51],
        [-40, 55],
        [23, 52],
        [3, 74],
        [41, 55],
        [13, 92],
        [-41, 59],
        [18, 75],
        [204, 35],
        [30, -5],
        [56, 51],
        [88, 18],
        [31, -47],
        [127, 13],
        [228, 70],
        [155, -42],
        [78, 100],
        [-176, 74],
        [13, 166],
        [60, 18],
        [13, 31],
        [87, -10],
        [-67, 95],
        [51, 124],
        [-69, -6],
        [-63, 11],
        [-110, -38],
        [-29, 47],
        [-127, 21],
        [-101, -35],
        [-98, 36],
        [-59, -21],
        [-57, -91],
        [-63, -36],
        [-122, -41],
        [-48, 9],
        [-32, -24],
        [-41, 38],
        [-50, 6],
        [-23, 31],
        [50, 72],
        [-19, 128],
        [71, 89],
        [-47, 15],
        [-26, -78],
        [-36, -37],
        [18, -93],
        [-41, -98],
        [-27, -5],
        [-100, 82],
        [-3, 62],
        [-91, 108],
        [-31, -36],
        [-44, -5],
        [-21, 64],
        [36, 200],
        [-66, 60],
        [-29, 1],
        [-67, -63],
        [-80, 66],
        [-29, -30],
        [-55, -7],
        [-88, 13],
        [-28, -49],
        [-114, 73],
        [29, 42],
        [117, 86],
        [-11, 90],
        [49, -30],
        [88, 0],
        [2, -35],
        [128, -4],
        [4, 43],
        [78, 79],
        [67, 9],
        [53, -61],
        [110, 1],
        [57, 88],
        [32, -51],
        [80, -75],
        [23, -69],
        [-64, -62],
        [-5, -117],
        [64, 3],
        [39, -110],
        [31, 51],
        [59, 38],
        [141, 0],
        [53, -63],
        [55, 18],
        [18, 48],
        [-101, 39],
        [-41, -21],
        [-16, 54],
        [0, 149],
        [-48, 27],
        [-55, -3],
        [-23, -81],
        [-78, -20],
        [-23, 51],
        [43, 39],
        [45, -3],
        [43, 37],
        [0, 71],
        [-26, 11],
        [-57, 70],
        [3, 69],
        [50, 18],
        [2, 78],
        [68, -60],
        [0, 133],
        [54, 74],
        [-40, 71],
        [-53, -107],
        [-21, 53],
        [-37, -5],
        [29, -86],
        [-5, -46],
        [-85, -24],
        [-21, -36],
        [-44, 28],
        [-78, -30],
        [20, 53],
        [-9, 67],
        [-78, 109],
        [25, 24],
        [83, 6],
        [67, 28],
        [34, 32],
        [50, -9],
        [-40, 84],
        [-44, -42],
        [-43, 77],
        [-41, 16],
        [54, 53],
        [-24, 95],
        [27, 34],
        [20, 104],
        [-49, -6],
        [-103, 99],
        [-44, 15],
        [-53, -29],
        [42, -33],
        [0, -103],
        [-33, -2],
        [-55, 45],
        [3, -93],
        [-35, -31],
        [-46, 1],
        [-70, -44],
        [-8, -42],
        [89, -25],
        [-29, -33],
        [13, -110],
        [-77, -12],
        [-3, -44],
        [75, -15],
        [18, -61],
        [-55, -71],
        [-51, 7],
        [-47, 50],
        [-41, -1],
        [-19, 63],
        [30, 40],
        [-14, 39],
        [23, 127],
        [35, 16],
        [23, 84],
        [35, 65],
        [6, 47],
        [-24, 64],
        [31, 72],
        [45, 3],
        [36, 41],
        [-2, 52],
        [-71, 19],
        [-64, 43],
        [51, 18],
        [5, 99],
        [35, 77],
        [53, 20],
        [3, 36],
        [60, -9],
        [25, 57],
        [53, 7],
        [-4, 47],
        [29, 27],
        [29, -105],
        [44, -14],
        [34, -77],
        [80, -24],
        [26, 11],
        [36, -43],
        [34, 4],
        [30, -41],
        [-35, -94],
        [-32, -50],
        [-65, -27],
        [-29, 15],
        [-89, -41],
        [82, -36],
        [150, 6],
        [60, 29],
        [16, 89],
        [-26, 36],
        [41, 177],
        [60, 29],
        [100, 21],
        [9, 22],
        [-68, 58],
        [-77, 37],
        [-8, 98],
        [34, 1],
        [75, 59],
        [5, 25],
        [103, 36],
        [50, -55],
        [53, 25],
        [34, -69],
        [129, -30],
        [21, -24],
        [112, -5],
        [41, 55],
        [116, -14],
        [32, -21],
        [72, 6],
        [-2, -47],
        [85, 23],
        [2, -32],
        [66, -1],
        [41, 31],
        [54, -1],
        [59, -38],
        [84, 9],
        [75, -5],
        [77, -74],
        [15, 66],
        [98, 92],
        [45, -47],
        [95, -30],
        [80, -12],
        [43, -63],
        [-19, -136],
        [117, 83],
        [21, -73],
        [-22, -79],
        [-2, -81],
        [62, 11],
        [10, -48],
        [-18, -52],
        [-43, -33],
        [-13, -39],
        [25, -34],
        [76, -8],
        [64, -31],
        [-10, -65],
        [49, -21],
        [21, -93],
        [-6, -153],
        [11, -55],
      ],
      [
        [12952, 12737],
        [-26, -96],
        [-10, -176],
        [21, -47],
        [-2, -64],
        [25, -190],
        [65, -202],
      ],
      [
        [10624, 11305],
        [88, 188],
        [19, 71],
        [0, 156],
        [19, 75],
        [86, 124],
        [-9, 64],
        [-61, 110],
        [-32, 15],
        [-182, -167],
        [-26, 16],
        [-39, 124],
        [-85, 16],
        [-87, 31],
        [-156, 75],
        [-75, 67],
        [-111, 123],
        [5, 128],
        [-12, 74],
        [-49, 129],
        [-6, 41],
      ],
      [
        [9804, 14552],
        [15, 103],
        [-54, 53],
        [0, 32],
        [64, 93],
        [57, -6],
        [2, -35],
        [-39, -70],
        [18, -33],
        [-25, -72],
        [40, -25],
        [32, 16],
        [63, 127],
        [5, 84],
        [24, 39],
        [-17, 108],
        [62, 32],
        [-30, 37],
        [121, 85],
        [102, -1],
        [57, 45],
        [-22, 245],
        [-75, 18],
        [6, 54],
        [-21, 30],
        [-63, 34],
        [20, 70],
        [58, 54],
        [6, 86],
        [71, 7],
        [144, 112],
        [-6, 68],
        [119, 155],
        [57, 88],
        [50, 1],
        [148, -78],
        [140, -122],
        [49, -63],
        [17, -92],
        [83, -28],
        [25, -94],
        [46, -219],
        [-20, -60],
        [90, -75],
        [53, -202],
        [42, -73],
        [178, -152],
        [75, 90],
        [34, 7],
        [134, -109],
        [46, -47],
        [23, -86],
        [-30, -115],
        [-75, -101],
        [43, -86],
        [-40, -61],
        [9, -51],
        [-30, -53],
        [59, -44],
        [74, -126],
        [119, 69],
      ],
      [
        [8688, 8428],
        [-39, -4],
        [-30, -40],
        [-78, -28],
        [-156, -15],
        [-79, -54],
        [-52, -157],
        [-33, -77],
        [-62, -77],
        [-87, -80],
        [-33, -91],
        [-60, -29],
        [-47, 18],
        [-65, 113],
        [-50, 62],
        [-47, 26],
        [-50, 106],
        [4, 47],
        [66, 84],
        [96, 11],
        [26, 25],
        [0, 183],
        [62, 175],
        [47, 160],
        [36, 84],
        [7, 154],
        [-19, 54],
        [-101, 113],
        [-93, 22],
        [-127, 95],
        [-139, 166],
      ],
      [
        [7726, 9938],
        [-29, 84],
        [0, 74],
        [24, 68],
        [153, 151],
        [153, 100],
        [6, 21],
      ],
      [
        [8033, 10436],
        [78, -75],
        [98, -57],
        [23, -35],
        [73, 46],
        [162, -11],
        [56, 15],
        [27, 54],
        [49, 54],
        [142, 74],
        [72, -32],
        [40, 10],
        [26, 101],
        [118, 20],
        [104, -54],
        [37, -67],
        [10, -150],
        [160, -10],
        [100, -67],
        [44, -15],
        [173, 5],
        [10, 48],
        [48, 49],
        [44, 82],
        [113, 60],
        [217, 155],
        [189, 92],
        [73, 11],
      ],
      [
        [7924, 11296],
        [7, -27],
        [0, -254],
        [22, -168],
        [42, -73],
        [49, -218],
        [-11, -120],
      ],
      [
        [6053, 13762],
        [126, -47],
        [169, -139],
        [89, -96],
        [92, -38],
        [62, -3],
        [100, 57],
        [53, 56],
        [41, 109],
        [-11, 128],
        [38, 67],
        [81, 181],
        [84, 155],
        [37, 21],
        [76, -7],
        [37, 24],
        [33, 62],
        [85, 114],
        [39, 84],
      ],
      [
        [6047, 15591],
        [40, -16],
        [-10, -65],
        [-41, 6],
        [-12, 45],
        [23, 30],
      ],
      [
        [5870, 16297],
        [-48, -47],
        [-16, 33],
        [64, 14],
      ],
      [
        [4447, 14641],
        [2, 97],
        [13, 99],
        [-2, 72],
        [24, 6],
        [4, 111],
        [-20, 36],
        [88, 15],
        [30, 127],
        [29, 40],
        [41, 128],
        [37, 54],
        [-10, 107],
        [127, 4],
        [12, -19],
        [72, 12],
        [26, -15],
        [83, 19],
        [53, -7],
        [35, -44],
        [40, -15],
        [31, -62],
        [66, -20],
        [31, -34],
        [109, -46],
        [65, 22],
        [45, 73],
        [48, 37],
        [44, 3],
        [-2, -53],
        [39, 3],
        [33, -30],
        [142, 54],
        [62, -6],
        [128, -193],
        [121, -109],
        [109, 23],
        [-14, 48],
        [-74, 5],
        [-11, 54],
        [-74, 67],
        [-11, 41],
        [-39, 27],
        [23, 36],
        [-22, 86],
        [33, 4],
        [57, -69],
        [95, 20],
        [105, -15],
        [28, 33],
        [61, -5],
        [-17, 52],
        [-98, 59],
        [-136, 8],
        [1, 64],
        [29, 47],
        [93, -54],
        [64, 4],
        [19, 39],
        [-86, 49],
        [-45, 7],
        [-25, 47],
        [-120, 6],
        [-69, -48],
        [-88, 13],
        [-68, 51],
        [21, 89],
        [63, 50],
        [61, -5],
        [19, 62],
        [70, 16],
        [49, 73],
        [16, 56],
        [51, -68],
        [44, 20],
        [67, 71],
        [86, 41],
        [0, 45],
        [47, 40],
        [4, 53],
        [-28, 30],
        [19, 87],
        [45, 29],
        [31, -15],
        [-17, -59],
        [33, -35],
        [68, 19],
        [38, 34],
        [89, 29],
      ],
      [
        [9725, 4966],
        [-27, -8],
        [-139, 7],
        [-44, 32],
        [-57, 3],
        [-68, 27],
        [-80, 11],
        [-110, -3],
        [-231, 5],
        [-105, -9],
        [-120, -26],
        [-101, -72],
        [-27, -35],
        [-70, -17],
        [-58, -65],
        [-15, -35],
        [4, -122],
        [25, -82],
        [58, -85],
        [76, -87],
        [-11, -67],
        [-72, -35],
        [-146, 7],
        [-103, -9],
        [-92, 6],
        [-125, 44],
        [-52, 35],
        [-87, 6],
        [-79, -75],
        [-92, -49],
        [-128, 0],
        [-71, -38],
        [-53, 48],
      ],
      [
        [10791, 4690],
        [8, -124],
        [25, -63],
        [16, -84],
      ],
      [
        [10840, 4419],
        [-36, -35],
        [6, -64],
        [-21, -53],
        [15, -34],
        [-34, -70],
        [40, -85],
        [-17, -87],
        [-51, -48],
        [10, -44],
        [-105, -66],
        [-89, 25],
        [-75, -19],
        [-46, -39],
        [-37, 39],
        [41, 37],
        [45, 82],
        [-59, 67],
        [-60, 8],
        [-55, -60],
        [-54, -26],
        [-36, -84],
        [-42, -33],
        [-70, 16],
        [-64, -18],
        [-160, 44],
        [-86, -24],
        [-168, 12],
        [-47, -54],
        [-124, 6],
        [-112, -68],
        [-77, -77],
        [-81, 17],
        [-69, -56],
        [-5, -63],
        [-31, -28],
        [-101, 106],
        [-14, -34],
        [-57, -27],
        [-46, -57],
        [-11, -54],
        [79, -9],
        [62, -73],
        [152, 18],
        [-38, -55],
        [-79, -53],
        [0, -46],
        [-28, -32],
        [23, -62],
        [-30, -96],
        [-76, -52],
        [-59, -3],
        [-94, -32],
        [-93, -3],
        [-62, -80],
        [-7, -51],
        [31, -48],
        [-107, -16],
        [-92, 73],
        [-42, -10],
        [-13, -45],
        [-46, -15],
        [-52, 9],
        [-13, 72],
        [25, 30],
        [-7, 59],
        [-65, 46],
      ],
      [
        [11883, 3780],
        [-8, -42],
        [-38, -37],
        [-21, 48],
        [67, 31],
      ],
      [
        [11914, 6944],
        [27, -43],
        [54, -9],
        [96, -68],
        [74, -5],
        [79, 18],
        [98, 55],
        [74, 72],
        [71, 141],
        [72, 159],
        [29, 113],
        [109, 59],
        [280, -2],
        [76, -23],
        [84, -86],
        [90, -43],
      ],
      [
        [13227, 7282],
        [23, -73],
        [-29, -62],
        [14, -75],
        [-44, -38],
        [-27, -64],
        [-62, -75],
        [-61, -173],
        [-52, -101],
        [-10, -129],
        [59, -125],
        [-10, -69],
        [28, -38],
        [2, -67],
        [26, -27],
        [-56, -164],
        [-71, -99],
        [-48, -103],
        [-112, -162],
        [-98, -192],
        [-85, -124],
        [-29, -139],
        [0, -97],
        [13, -79],
        [-46, -26],
        [-31, 54],
        [-154, 2],
        [-41, -60],
        [-55, 45],
        [-4, 73],
        [-31, -1],
        [-27, -63],
        [83, -56],
        [53, -90],
        [-3, -108],
        [43, -2],
        [60, -52],
        [65, 36],
        [-5, -101],
        [20, -83],
        [57, -100],
        [93, 18],
        [21, -51],
        [42, -29],
        [-54, -107],
        [-31, -28],
        [-32, -74],
        [24, -68],
        [-45, -82],
        [-92, 19],
        [-86, 53],
        [-125, 18],
        [-80, 0],
        [-150, -30],
        [-75, -47],
        [-62, -19],
        [-77, 84],
        [-125, 99],
        [-157, 56],
        [-86, -32],
        [-49, 9],
        [-41, -40],
        [-52, -8],
        [-52, 44],
        [46, 24],
        [8, 67],
        [94, 101],
        [-3, 41],
        [-78, -66],
        [-38, 0],
        [-122, -142],
        [51, -37],
        [-31, -94],
        [5, -93],
        [-49, -13],
        [-25, -32],
        [-83, -31],
        [-39, -77],
        [-26, -6],
        [-11, -83],
        [-74, -40],
        [-5, 52],
        [97, 125],
        [9, 93],
        [-45, 95],
        [26, 35],
        [-26, 67],
        [-54, 57],
        [-39, 81],
        [-39, 35],
      ],
      [
        [13343, 9710],
        [37, -120],
        [41, -27],
        [21, -91],
        [-15, -66],
        [84, -171],
        [19, -74],
        [21, -266],
        [-34, -155],
        [-11, -209],
        [14, -75],
        [113, -81],
        [21, -68],
        [-44, -48],
        [-7, -91],
        [-63, -98],
        [-2, -101],
        [-34, -30],
        [-33, -79],
        [-8, -67],
        [22, -37],
        [-134, -98],
        [-36, -66],
        [-13, -59],
        [-68, -96],
        [8, -46],
        [-15, -109],
      ],
    ],
    objects: {
      IRL_adm1: {
        type: 'GeometryCollection',
        geometries: [
          {
            arcs: [[0, 1, 2, 3, 4]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1322,
              NAME_1: 'Carlow',
              VARNAME_1: 'Ceatharlach',
              HASC_1: 'IE.CW',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[5, 6, 7, 8, 9, 10, 11]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1323,
              NAME_1: 'Cavan',
              VARNAME_1: 'An Cabhán',
              HASC_1: 'IE.CN',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[[12]], [[13, 14, 15, 16]]],
            type: 'MultiPolygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1324,
              NAME_1: 'Clare',
              VARNAME_1: 'An Clár',
              HASC_1: 'IE.CE',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [
              [[17]],
              [[18]],
              [[19]],
              [[20]],
              [[21]],
              [[22]],
              [[23, 24, 25, 26, 27]],
            ],
            type: 'MultiPolygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1325,
              NAME_1: 'Cork',
              VARNAME_1: 'Corcaigh',
              HASC_1: 'IE.CK',
              TYPE_1: 'Traditional County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [
              [[28]],
              [[29]],
              [[30]],
              [[31]],
              [[32]],
              [[33]],
              [[34]],
              [[35, 36, 37]],
            ],
            type: 'MultiPolygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1326,
              NAME_1: 'Donegal',
              VARNAME_1: 'Dún na nGall|Tyrconnel',
              HASC_1: 'IE.DL',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[[38]], [[39]], [[40, 41, 42, 43]]],
            type: 'MultiPolygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1327,
              NAME_1: 'Dublin',
              VARNAME_1: 'Baile Átha Cliath',
              HASC_1: 'IE.DN',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [
              [[44]],
              [[45]],
              [[46]],
              [[47]],
              [[48]],
              [[49]],
              [[50]],
              [[51]],
              [[52]],
              [[53, 54, -14, 55, 56, 57]],
            ],
            type: 'MultiPolygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1328,
              NAME_1: 'Galway',
              VARNAME_1: 'Gaillimh',
              HASC_1: 'IE.GY',
              TYPE_1: 'Traditional County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[[58]], [[59]], [[60]], [[61, -27, 62]]],
            type: 'MultiPolygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1329,
              NAME_1: 'Kerry',
              VARNAME_1: 'Ciarraí',
              HASC_1: 'IE.KY',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-42, 63, -4, 64, 65, 66, 67]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1330,
              NAME_1: 'Kildare',
              VARNAME_1: 'Cill Dara',
              HASC_1: 'IE.KE',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[68, 69, 70, 71, -2]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1331,
              NAME_1: 'Kilkenny',
              VARNAME_1: 'Cill Chainnigh',
              HASC_1: 'IE.KK',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-65, -3, -72, 72, 73]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1332,
              NAME_1: 'Laoighis',
              VARNAME_1: 'Laois|Leix|Queens',
              HASC_1: 'IE.LS',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-37, 74, -11, 75, 76, 77, 78]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1333,
              NAME_1: 'Leitrim',
              VARNAME_1: 'Liatroim',
              HASC_1: 'IE.LM',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-28, -62, 79, -16, 80]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1334,
              NAME_1: 'Limerick',
              VARNAME_1: 'Luimneach',
              HASC_1: 'IE.LK',
              TYPE_1: 'Traditional County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[81, 82, -76, -10]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1335,
              NAME_1: 'Longford',
              VARNAME_1: 'An Longfort',
              HASC_1: 'IE.LD',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[83, -7, 84, 85]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1336,
              NAME_1: 'Louth',
              VARNAME_1: 'Lú',
              HASC_1: 'IE.LH',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [
              [[86]],
              [[87]],
              [[88]],
              [[89]],
              [[90]],
              [[91]],
              [[92, 93, -57, 94]],
            ],
            type: 'MultiPolygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1337,
              NAME_1: 'Mayo',
              VARNAME_1: 'Maigh Eo',
              HASC_1: 'IE.MO',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[95, -43, -68, 96, -8, -84]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1338,
              NAME_1: 'Meath',
              VARNAME_1: 'An Mhí',
              HASC_1: 'IE.MH',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-85, -6, 97]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1339,
              NAME_1: 'Monaghan',
              VARNAME_1: 'Muineachán',
              HASC_1: 'IE.MN',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-74, 98, -54, 99, 100, -66]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1340,
              NAME_1: 'Offaly',
              VARNAME_1: 'Uíbh Fhailí|Kings',
              HASC_1: 'IE.OY',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-83, 101, -100, -58, -94, 102, -77]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1341,
              NAME_1: 'Roscommon',
              VARNAME_1: 'Ros Comáin',
              HASC_1: 'IE.RN',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[[103]], [[104]], [[-78, -103, -93, 105]]],
            type: 'MultiPolygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1342,
              NAME_1: 'Sligo',
              VARNAME_1: 'Sligeach',
              HASC_1: 'IE.SO',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-73, -71, 106, -24, -81, -15, -55, -99]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1343,
              NAME_1: 'Tipperary',
              VARNAME_1: 'Tiobraid Árann',
              HASC_1: 'IE.TY',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-70, 107, 108, -25, -107]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1344,
              NAME_1: 'Waterford',
              VARNAME_1: 'Port Láirge',
              HASC_1: 'IE.WD',
              TYPE_1: 'Traditional County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[-97, -67, -101, -102, -82, -9]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1345,
              NAME_1: 'Westmeath',
              VARNAME_1: 'An Iarmhí',
              HASC_1: 'IE.WH',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[[109]], [[-108, -69, -1, 110, 111]]],
            type: 'MultiPolygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1346,
              NAME_1: 'Wexford',
              VARNAME_1: 'Loch Garman',
              HASC_1: 'IE.WX',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
          {
            arcs: [[112, -111, -5, -64, -41]],
            type: 'Polygon',
            properties: {
              ISO: 'IRL',
              NAME_0: 'Ireland',
              ID_1: 1347,
              NAME_1: 'Wicklow',
              VARNAME_1: 'Cill Mhantáin',
              HASC_1: 'IE.WW',
              TYPE_1: 'Administrative County',
              ENGTYPE_1: 'County',
            },
          },
        ],
      },
    },
  };
}
