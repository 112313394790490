import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as classes from './busWidget.module.scss';

const FIVE_MINS = 300000;
// const FIVE_MINS = 300000;
export async function fetchBusData(stopId = '8460B5230201') {
	//  FRANCIS STREET OPP ABBEY CHURCH
	const url = `https://galway-bus.apis.ie/api/realtime/gstoptimes/${stopId}`;
	const response = await fetch(url);
	const ans = await response.json();
	const noRealTimeRoutes = ['410', '411', '412', '414'];
	// filter out stops with rt info and for each route only keep the first 2 results
	const now = new Date().toLocaleTimeString('en-IE').substring(0, 5);
	const filteredForRT = ans.results.map((r) => {
		const output = r.g_stop_times.filter((st) => {
			// Some routes never have realtime, don't exclude
			if (noRealTimeRoutes.includes(st.route_short_name)) {
				return st.departure_time.substring(0, 5) > now;
			}

			// Buses tend to stay on the feed for a while, the first two will probably have recently departed. So we filter first for gStopTimes with realtime & a departure_time in the future
			return st.rt !== null && st.departure_time.substring(0, 5) > now;
		});

		if (output.length > 2) {
			const withTwo = output.slice(0, 2);

			return withTwo;
		}
		return output;
	});

	return filteredForRT;
}

async function fetchStopsList() {
	const url = `https://galway-bus.apis.ie/api/gstop/bystopid`;
	const response = await fetch(url);
	const ans = await response.json();

	return ans.results.stops;
}

function BusWidget() {
	const [busData, setBusData] = useState(null);
	const [stops, setStops] = useState(null);
	const [selectedStopId, setSelectedStopId] = useState('8460B5230201');
	const [selectedStopName, setSelectedStopName] = useState(
		'FRANCIS STREET OPP ABBEY CHURCH'
	);
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [time, setTime] = useState(new Date().toLocaleTimeString('en-IE'));
	const [glow, setGlow] = useState(true);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	// Load data
	useEffect(() => {
		async function getData() {
			try {
				setLoading(true);
				const ans = await fetchBusData(selectedStopId);
				if (ans) {
					setBusData(ans);
					setLoading(false);
				}
			} catch (e) {
				setError(true);
				setLoading(false);
				console.log('error obs', e);
			}
		}
		getData();
	}, [selectedStopId]);

	// Load stops list for dropdown
	useEffect(() => {
		async function getStops() {
			try {
				const ans = await fetchStopsList();
				if (ans) {
					setStops(ans);
				}
			} catch (e) {
				console.log('error stops', e);
			}
		}
		getStops();
	}, []);

	// do clock
	useEffect(() => {
		const clock = setInterval(
			() => setTime(new Date().toLocaleTimeString('en-IE')),
			1000
		);
		return () => {
			clearInterval(clock);
		};
	});

	const savedCallback = useRef();

	async function getDataAgain() {
		try {
			const ans = await fetchBusData(selectedStopId);
			if (ans) {
				setBusData(ans);
				setLoading(false);
			}
		} catch (e) {
			setError(true);
			setLoading(false);
			console.log('error obs', e);
		}
	}

	function onChangeField(e) {
		const id = e.target.value;
		const stop = stops.find((s) => s.stop_id === id);

		if (id && stop) {
			setSelectedStopId(id);

			setSelectedStopName(stop.stop_name);
		}
		return;
	}

	useEffect(() => {
		savedCallback.current = getDataAgain;
	});

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}

		let updateDataInterval = setInterval(tick, FIVE_MINS);
		return () => clearInterval(updateDataInterval);
	}, []);

	// close details when a new stop is selected
	useEffect(() => {
		setDetailsOpen(false);
	}, [selectedStopId]);

	const niceMinutes = (r) => {
		let output = '?';

		// There's 5 scenarios.
		// 1. City Direct never has realtime
		if (r.rt === null) {
			output = '-';
		}

		// 2. Delay is 0
		if (r.rt !== null && r.departure_delay.toString() === '0') {
			output = '0 min';
		}

		// 3. Delay is > 0
		if (r.rt !== null && r.departure_delay && r.departure_delay > 0) {
			const delay = parseInt(r.departure_delay / 60);
			const min =
				delay && delay > 1 ? 'mins' : delay && delay <= 1 ? 'min' : '';
			const ans = `+ ${delay} ${min}`;
			output = ans;
		}
		// 4. Delay is < 0 Early
		if (r.rt !== null && r.departure_delay && r.departure_delay < 0) {
			const delay = parseInt(r.departure_delay / 60);
			const min =
				delay && delay > 1 ? 'mins' : delay && delay <= 1 ? 'min' : '';
			const ans = `- ${delay} ${min}`;
			output = ans;
		}

		// 5. Either something is wrong or - GTFS-R StopTime.departure only HAS to list .delay or .time - so if StopTime.departure.delay is omitted in the feed or set to 'null' departure_delay will be null, output will still be '-'.
		return output;
	};

	// Because of the clock the 'departure board' renders every second - use useMemo so it only figures out the dynamic part when busData changes.
	const niceBusDataMemo = useMemo(() => {
		return (
			busData?.length &&
			busData.map((route, i) =>
				route?.length
					? route.map((r) => (
							<tr key={r.trip_id}>
								<td>{r.route_short_name}</td>
								<td>{r.last_stop_name.substring(0, 10)}</td>
								<td>{r.departure_time}</td>
								<td>{niceMinutes(r)}</td>
							</tr>
					  ))
					: null
			)
		);
	}, [busData]);

	return (
		<>
			<button onClick={() => setGlow(!glow)}>
				<span className={classes.srOnly}>
					Toggle the potientally annoying glowing text on and off.
				</span>
				{glow ? (
					<img
						alt="A lightbulb"
						width="18px"
						height="18px"
						src="data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg' fill='%23cfdd03' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Pro 6.1.1 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons  Inc. --%3E%3Cpath d='M112.1 454.3c0 6.297 1.816 12.44 5.284 17.69l17.14 25.69c5.25 7.875 17.17 14.28 26.64 14.28h61.67c9.438 0 21.36-6.401 26.61-14.28l17.08-25.68c2.938-4.438 5.348-12.37 5.348-17.7L272 415.1h-160L112.1 454.3zM191.4 .0132C89.44 .3257 16 82.97 16 175.1c0 44.38 16.44 84.84 43.56 115.8c16.53 18.84 42.34 58.23 52.22 91.45c.0313 .25 .0938 .5166 .125 .7823h160.2c.0313-.2656 .0938-.5166 .125-.7823c9.875-33.22 35.69-72.61 52.22-91.45C351.6 260.8 368 220.4 368 175.1C368 78.61 288.9-.2837 191.4 .0132zM192 96.01c-44.13 0-80 35.89-80 79.1C112 184.8 104.8 192 96 192S80 184.8 80 176c0-61.76 50.25-111.1 112-111.1c8.844 0 16 7.159 16 16S200.8 96.01 192 96.01z'/%3E%3C/svg%3E"
					/>
				) : (
					<img
						alt="A lightbulb"
						src="data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Pro 6.1.1 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons  Inc. --%3E%3Cpath d='M112.1 454.3c0 6.297 1.816 12.44 5.284 17.69l17.14 25.69c5.25 7.875 17.17 14.28 26.64 14.28h61.67c9.438 0 21.36-6.401 26.61-14.28l17.08-25.68c2.938-4.438 5.348-12.37 5.348-17.7L272 415.1h-160L112.1 454.3zM191.4 .0132C89.44 .3257 16 82.97 16 175.1c0 44.38 16.44 84.84 43.56 115.8c16.53 18.84 42.34 58.23 52.22 91.45c.0313 .25 .0938 .5166 .125 .7823h160.2c.0313-.2656 .0938-.5166 .125-.7823c9.875-33.22 35.69-72.61 52.22-91.45C351.6 260.8 368 220.4 368 175.1C368 78.61 288.9-.2837 191.4 .0132zM192 96.01c-44.13 0-80 35.89-80 79.1C112 184.8 104.8 192 96 192S80 184.8 80 176c0-61.76 50.25-111.1 112-111.1c8.844 0 16 7.159 16 16S200.8 96.01 192 96.01z'/%3E%3C/svg%3E"
					/>
				)}
			</button>

			<table
				className={`${glow ? classes.glow : classes.noGlow} ${
					classes.busWidgetTable
				}`}
			>
				<caption>
					LIVE Departures -
					<details
						open={detailsOpen}
						onToggle={(e) => setDetailsOpen(e.target.open)}
						className={`${
							detailsOpen ? classes.detailsOpen : classes.detailsClosed
						}`}
					>
						<summary>{selectedStopName.toUpperCase()}</summary>
						<div className={classes.stopDropdownWrap}>
							{stops ? (
								<fieldset>
									<label htmlFor="select-stop">Select stop:</label>
									<select
										id="select-stop"
										onChange={onChangeField}
										className={classes.doSelect}
										value={selectedStopId}
									>
										{stops.map((s, i) => (
											<option key={s.stop_id} id={s.stop_id} value={s.stop_id}>
												{s.stop_name.substring(0, 10)}
											</option>
										))}
									</select>
								</fieldset>
							) : null}
						</div>
					</details>
					{time}
				</caption>
				<thead>
					<tr>
						<td>Route</td>
						<td>Destination</td>
						<td>Scheduled</td>
						<td>Realtime</td>
					</tr>
				</thead>
				<tbody>
					{!loading && !error && busData?.length ? (
						niceBusDataMemo
					) : !error && loading ? (
						<tr className={glow ? classes.glow : classes.noGlow}>
							<td>Loading...</td>
						</tr>
					) : (
						<tr className={glow ? classes.redGlow : classes.noGlow}>
							<td colSpan={4} text-align="center">
								Out of Service
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	);
}

export default BusWidget;
