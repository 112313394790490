import React, { useEffect, useState } from 'react';
import * as classes from './weatherWidget.module.scss';
import SelectField from './selectField/SelectField';
import embed from 'vega-embed';
import dummyFreeState from '../../images/basic_freestate_small.png';
import {
	layer,
	markCircle,
	text,
	tooltip,
	fieldN,
	fill,
	markGeoshape,
	topojson,
	longitude,
	latitude,
	markText,
} from 'vega-lite-api';

import { topo } from './topo.js';

const ireland = topo();

export async function fetchPresentObs() {
	const url = 'https://apis.ie/weather/api';

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},

		body: JSON.stringify({
			query: `
          query {
            presentObservations {
              stations {
                name
                stationNumber
                longitude,
                latitude,
                symbol
                wind_speed{
                  value
                  unit
                }
                temp{
                  value
                  unit
                }
                wind_direction
                pressure{
                  value
                  unit
                }
                weather_text
                rainfall{
                  value
                  unit
                }
                humidity{
                  value
                  unit
                }
              }
            }
          }
        `,
		}),
	});
	const ans = await response.json();

	// Met use '-99' for unknown values. Replace these with question  marks.
	const withQuestionMarksForUnknowns =
		ans.data.presentObservations.stations.map((s) => {
			const update = { ...s };

			if (s.wind_direction === '-99') {
				update.wind_direction = '?';
			}
			if (s.pressure.value === '-99') {
				update.pressure.value = '?';
			}
			if (s.wind_speed.value === '-99') {
				update.wind_speed.value = '?';
			}
			if (s.rainfall.value === '-99') {
				update.rainfall.value = '?';
			}
			if (s.humidity.value === '-99') {
				update.humidity.value = '?';
			}
			return update;
		});

	return withQuestionMarksForUnknowns;
	// return ans.data.presentObservations.stations;
}

function WeatherWidget() {
	const [presentObs, setPresentObs] = useState(null);
	const [stuff, setStuff] = useState(null);
	const [showDummyImage, setShowDummyImage] = useState(true);
	const [selectedField, setSelectedField] = useState({
		name: 'temp',
		unit: 'deg cenesius',
		displayName: 'Temperature',
	});
	const [trick, setTrick] = useState(null);

	// Load data
	useEffect(() => {
		async function getData() {
			try {
				const ans = await fetchPresentObs();
				if (ans) {
					setPresentObs(ans);
				}
			} catch (e) {
				console.log('error obs', e);
			}
		}
		getData();
	}, []);

	// When the data (presentObs) or the selected field (trick) changes, do the embed.
	useEffect(() => {
		if (!presentObs || !mapArea) return;

		async function prepareTheSpec() {
			// Prepare for embed, do map, squares & text layers.

			const map = await layer(mapArea, squares, textPoints)
				.width(220)
				.height(300)
				.config({ view: { stroke: null } });

			// Turn map into json spec & set state.
			const mapSpec = await map.toSpec();

			setStuff(mapSpec);
		}

		prepareTheSpec();
	}, [presentObs, trick]);

	// When selectedField changes, set 'trick' for vega-lite
	useEffect(() => {
		// weather_text & wind_direction are strings, they do not have a unit.

		if (
			selectedField.name === 'weather_text' ||
			selectedField.name === 'wind_direction'
		) {
			setTrick(`${selectedField.name}`);
		}
		// else if (selectedField.name) {

		// }
		else {
			setTrick(`${selectedField.name}.value`);
		}
	}, [selectedField]);

	// If spec is set, do the embed.
	useEffect(() => {
		if (!stuff) return;

		async function doEmbed() {
			try {
				await embed('#chart', stuff, {
					renderer: 'svg',
					padding: { left: 5, top: 5, right: 5, bottom: 5 },
				});
				setShowDummyImage(false);
			} catch (e) {
				console.log('embed error', e);
			}
		}
		doEmbed();
	}, [stuff]);

	// Prepare the map.
	const mapArea = markGeoshape({
		fill: '#d1dddd',
		stroke: '#fff',
		strokeWidth: 1,
	}).data(topojson(ireland).feature('IRL_adm1'));

	// Prepare text value of whatever field is selected (trick).
	const textPoints = markText({ fontSize: 10 })
		.data(presentObs)
		.encode(
			latitude().fieldQ('latitude'),
			longitude().fieldQ('longitude'),
			tooltip([fieldN('name'), fieldN(trick)]),
			text({ field: trick, type: 'nominal' })
		);

	// Prepare dodgy squares behind the text.
	const squares = markCircle({ size: 500, opacity: 1 })
		.data(presentObs)
		.encode(
			latitude().fieldQ('latitude'),
			longitude().fieldQ('longitude'),
			fill().value('orange')
		);

	return (
		
			<div className={classes.weatherWidget}>
				<SelectField
					selectedField={selectedField}
					setSelectedField={setSelectedField}
				/>
				{
					showDummyImage && <img alt="Placeholder outline of Irish Free State"  className={classes.dummy} src={dummyFreeState} />
				}
				<div id="chart" className={classes.chart}></div>
			</div>
	
	);
}

export default WeatherWidget;
