import * as React from 'react';
import * as classes from './index.module.scss';

import WeatherWidget from '../components/weatherWidget/WeatherWidget';
import BusWidget from '../components/busWidget/BusWidget';
import Seo from '../components/seo/Seo';


const IndexPage = () => {
	return (
		<>
			<Seo title="Home" />
			<div className={classes.wrap}>
				<div className={classes.content}>
					<header>
						<h1>APIS.ie</h1>
					</header>
					<main>
						<p>Welcome to the landing page for APIs.ie.</p>
						<article className={classes.weatherArticle}>
							<div className={classes.titleWidget}>
								<WeatherWidget />
								<span>
									Data from Met Éireann's "Present Observations" forecast via
									Irish Weather API
								</span>
							</div>
							<div className={classes.textWrap}>
								<h2>Irish Weather API</h2>
								<p>
									Irish Weather API is a GraphQL API. It provides access to over
									500 Met Éireann resources from a single endpoint.
								</p>

								<a href="https://weather.apis.ie">weather.apis.ie</a>
							</div>
						</article>

						<article className={classes.busArticle}>
							<div className={classes.titleWidget}>
								<BusWidget />
								<span>
									Data from TFI's Static GTFS & GTFS-R feed via Galway Bus API.
								</span>
							</div>
							<div className={classes.textWrap}>
								<h2>Galway Bus API</h2>
								<p>
									Galway Bus API is a REST API serving realtime information
									about Galway City Buses.
								</p>
								<a href="https://galway-bus.apis.ie">galway-bus.apis.ie</a>
							</div>
						</article>
					</main>
					<footer>
						<img
							width="32"
							height="32"
							alt="Marie Logo"
							src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.839 23.839'%3E%3Cpath fill='%234a0f59' stroke='%23fff' stroke-width='.026458' d='M.013.013V23.826H23.826V.013H.013zm5.292 5.292h5.292v2.646H7.95V18.534H5.305V5.305zm7.938 0h5.291v13.229h-2.646V7.951h-2.645V5.305z' paint-order='markers fill stroke'/%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-width='.026458' d='M5.305 5.305v13.229h2.646V7.951h2.646V5.305H5.305zm5.292 2.646v2.646h2.646V7.95h-2.646zm2.646 0h2.645V18.534h2.646V5.305h-5.291v2.646z' paint-order='markers fill stroke'/%3E%3C/svg%3E"
						/>
						<span>
							© {new Date().getFullYear()} <a href="https://marie.ie">Marie</a>
						</span>
					</footer>
				</div>
			</div>
		</>
	);
};

export default IndexPage;
