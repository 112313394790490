import React from 'react';
import * as classes from './selectField.module.scss';

const possibleFields = [
	{ name: 'temp', unit: 'deg cenesius', displayName: 'Temperature' },
	{ name: 'pressure', unit: 'hPa', displayName: 'Pressure' },
	{ name: 'weather_text', unit: null, displayName: 'Weather' },
	{ name: 'wind_speed', unit: 'kts', displayName: 'Wind Speed' },
	{ name: 'wind_direction', unit: null, displayName: 'Wind Direction' },
	{ name: 'rainfall', unit: 'mm', displayName: 'Rainfall' },
	{ name: 'humidity', unit: '%', displayName: 'Humidity' },
];

export default function SelectField({ setSelectedField, selectedField }) {
	function onChangeField(e) {
		const index = e.target.value;
		const update = {
			name: possibleFields[index].name,
			unit: possibleFields[index].unit,
			displayName: possibleFields[index].displayName,
		};

		setSelectedField(update);
	}
	return (
		<>
			<select
				className={classes.selectField}
				onChange={(e) => onChangeField(e)}
				defaultValue={selectedField.name}
			>
				{possibleFields.map((s, i) => {
					return (
						<option key={i} value={i} id={i}>
							{s.displayName} {s.unit && ' - ' + s.unit}
						</option>
					);
				})}
			</select>
		</>
	);
}
